'use client';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useRef } from 'react';
interface ModalProps {
  open: boolean;
  onClose: any;
  children: React.ReactNode;
}
export const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children
}) => {
  const cancelButtonRef = useRef(null);
  return <Transition.Root show={open} as={Fragment} data-sentry-element="unknown" data-sentry-component="Modal" data-sentry-source-file="Modal.tsx">
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} static open={open} onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="Modal.tsx">
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none " onClick={onClose} ref={cancelButtonRef}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="XMarkIcon" data-sentry-source-file="Modal.tsx" />
                  </button>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
};