'use client';

import React, { ComponentType, createContext, useContext, useMemo, useState } from 'react';
import { Modals } from './ManagedModal';
interface ModalDispathAction {
  open: any;
  close: any;
}
interface ModalComponent {
  Component: ComponentType;
  props: any;
}
interface Props {
  children: React.ReactNode;
}
export const ModalsDispatchContext = createContext<ModalDispathAction>({
  open: () => {},
  close: () => {}
});
export const ModalsStateContext = createContext<ModalComponent[]>([]);
export const ModalsProvider: React.FC<Props> = ({
  children
}) => {
  const [openedModals, setOpenedModals] = useState<ModalComponent[]>([]);
  const open = (Component: ComponentType, props: any) => {
    setOpenedModals(modals => {
      return [...modals, {
        Component,
        props
      }];
    });
  };
  const close = (Component: ComponentType) => {
    setOpenedModals(modals => {
      return modals.filter(modal => {
        return modal.Component !== Component;
      });
    });
  };
  const dispatch = useMemo(() => ({
    open,
    close
  }), []);
  return <ModalsStateContext.Provider value={openedModals} data-sentry-element="unknown" data-sentry-component="ModalsProvider" data-sentry-source-file="Modal.Context.tsx">
      <ModalsDispatchContext.Provider value={dispatch} data-sentry-element="unknown" data-sentry-source-file="Modal.Context.tsx">
        {children}
        <Modals data-sentry-element="Modals" data-sentry-source-file="Modal.Context.tsx" />
      </ModalsDispatchContext.Provider>
    </ModalsStateContext.Provider>;
};
export default function useModals() {
  const {
    open,
    close
  } = useContext(ModalsDispatchContext);
  const openModal = (Component: ComponentType<{
    onClose: () => void;
  }>, props: any) => {
    open(Component, props);
  };
  const closeModal = (Component: ComponentType<{
    onClose: () => void;
  }>) => {
    close(Component);
  };
  return {
    openModal,
    closeModal
  };
}