import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { Modal } from './Modal';
import { ModalsDispatchContext, ModalsStateContext } from './Modal.Context';
export const modals = {
  userDeleteModal: dynamic(async () => await import('@/components/Accounts/Profile/UserDeleteView')),
  userChangePasswordModal: dynamic(async () => await import('@/components/Accounts/Profile/UserChangePasswordModal')),
  userCreatePasswordModal: dynamic(async () => await import('@/components/Accounts/Profile/UserCreatePasswordModal'))
};
export function Modals() {
  const openedModals = useContext(ModalsStateContext);
  const {
    close
  } = useContext(ModalsDispatchContext);
  return openedModals.map((modal, index) => {
    const {
      Component,
      props
    } = modal;
    const {
      onSubmit,
      ...restProps
    } = props;
    const onClose = () => {
      close(Component);
    };
    return <Modal key={index} open onClose={onClose}>
        <Component {...restProps} onClose={onClose} onSubmit={onSubmit} />
      </Modal>;
  });
}